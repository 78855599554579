import { getAuth } from "@firebase/auth";
import { Menu, Transition } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useHistory } from "react-router";

import { useUser } from "reactfire";

export default function ProfileIcon() {
  const { data: user } = useUser();
  const history = useHistory();

  async function onSignOut() {
    const auth = getAuth();
    await auth.signOut();
    history.replace("/");
  }

  return (
    <div className=" fixed right-4 top-2">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full font-medium bg-black hover:bg-opacity-30 focus:outline-none focus-whithin:ring-2 focus-whithin:ring-white focus-whithin:ring-opacity-75 rounded-full hover:shadow-lg">
            <img
              src={user?.photoURL!}
              alt=""
              className="rounded-full w-11 h-11"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onSignOut}
                    className={`${
                      active ? "bg-secondary text-white" : "text-secondary"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    <LogoutIcon className="w-5 h-5 mr-2 " aria-hidden="true" />
                    Cerrar sesión
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
