import "./index.css";
// import { XIcon } from "@heroicons/react/solid";
import { logEvent } from "@firebase/analytics";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import {
  useAnalytics, useSigninCheck
} from "reactfire";

import logEventNames from "../../services/analytics";

import LastEvaluations from "../../components/LastEvaluations";
import { SEOPage } from "../../components/SeoPage";
// import LastEvaluations from "../../components/LastEvaluations";

export default function EvaluationPage() {
  const analytics = useAnalytics();

  const onClick = async () => {
    const { evaluate_click: eventName } = logEventNames;
    logEvent(analytics, eventName);
  };

  const onFaqClick = async (id: string) => {
    const { faq_click: eventName } = logEventNames;
    logEvent(analytics, eventName, {
      question_id: id,
    });
  };

  interface FaqQuestion {
    key: string;
    ask: string;
    response: string | JSX.Element;
  }
  const faq: FaqQuestion[] = [
    {
      key: "centros",
      ask: "¿Funciona en todos los centros?",
      response:
        "En esta primera etapa y para validar que todos los servicios están funcionando como deberían, sólo se podrán evaluar y ver maestros de CUCEI . No se descartan futuros centros.",
    },
    {
      key: "anonimidad",
      ask: "¿Las evaluaciones son anónimas?",
      response:
        "Sí. Todas las evaluaciones que se han hecho se podrán ver pero tu nombre no será visible.",
    },
    // {
    //   key: "porcentaje",
    //   ask: "¿Por qué ha bajado el porcentaje de evaluaciones de un momento a otro?",
    //   response: (
    //     <>
    //       Se verificará que las evaluaciones se hayan realizado de acuerdo a las{" "}
    //       <a
    //         className="hover:underline text-blue-700"
    //         href="/politicas#evaluaciones"
    //       >
    //         Reglas para evaluaciones
    //       </a>{" "}
    //       y en caso de qué no se cumplan, se eliminarán, esto puede causar que
    //       la barra de progreso disminuya.
    //     </>
    //   ),
    // },
    // {
    //   key: "espera",
    //   ask: "¿Por qué esperar para abrir la plataforma?",
    //   response:
    //     "El servicio de evaluaciones sólo es posible si existe una base de conocimiento en la que los estudiantes puedan confiar. Contar con 1000 evaluaciones ayuda a crear esa base sólida para evitar encontrarte con maestros sin evaluar.",
    // },
    {
      key: "sesion_evaluaciones",
      ask: "¿Debo iniciar sesión cuando quiera ver evaluaciones?",
      response:
        " No. Será un servicio de uso público pero se recomienda tener una cuenta para acceder a todas las funcionalidades disponibles.",
    },
    {
      key: "aportar_evaluaciones",
      ask: "¿Cómo aporto con mis evaluaciones?",
      response:
        "Primero deberás registrarte / iniciar sesión con cualquiera de los métodos disponibles. Luego podrás ingresar a la plataforma y evaluar a los maestros que desees.",
    },
    {
      key: "reglas",
      ask: "¿Por qué son necesarias las reglas?",
      response:
        "Las reglas mantienen la plataforma con un estándar de cálidad en su contenido y procuran el seguimiento de la normatividad expedida por la Universidad de Guadalajara para los estudiantes.",
    },
    {
      key: "intencion",
      ask: "¿Qué es la intención de esta plataforma?",
      response:
        "Ayudar a los estudiantes en su agenda académica y a los maestros en su evaluación. NO pretende afectar la integridad de los maesttos.",
    },
    // {
    //   key: "limites",
    //   ask: "¿Sólo se abrirá si se llega a 1000?",
    //   response:
    //     "El 19 de Noviembre se abrirá la plataforma con las evaluaciones que se hayan recaudado en caso de no llegar a 1000.",
    // },
  ];

  const { data: auth } = useSigninCheck({
    initialData: false,
  });

  // const firestore = useFirestore();
  // const { data: counterData } = useFirestoreDocData(
  //   doc(firestore, FIRESTORE_TEACHERS_COUNT)
  // );

  return (
    <div className="min-h-screen w-full xl:px-72 lg:px-44 md:px-24 px-8 mb-8">
      <SEOPage
        title="Buscador de maestros"
        description="Busca las evaluaciones de tus maestros UDG"
      />
      <div className="grid space-y-4 mt-10 md:mt:14 lg:mt-18 mb-4">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-secondary tracking-tight">
          Evaluaciones de maestros
        </h1>

        <h2 className="sm:text-lg lg:text-xl w-full sm:w-4/5">
          Esta funcionalidad permite a estudiantes ver reseñas de maestros
          hechas por otros estudiantes para decidir qué sección agendar según sus propios criterios.
        </h2>

        <div className="pb-8">
          <span onClick={onClick}>
            <Link
              to="/evaluar"
              className="text-secondary bg-primary rounded py-2 px-4 hover:opacity-80 text-center text-sm sm:text-base font-medium sm:text-left md:text-lg"
            >
              {auth?.signedIn ?? false
                ? "Evaluar a mis maestros"
                : "Iniciar sesión y evaluar a mis maestros"}
            </Link>
          </span>
          {/* <h4 className="text-xs mt-3">
            La plataforma se abrirá cuando se hayan recaudado 1000 evaluaciones
          </h4> */}
        </div>
        <section>
          <h3 className="text-xl font-bold text-secondary">Recientes</h3>
          <LastEvaluations/>
        </section>
        {/* <section>
          <h3 className="text-xl font-bold text-secondary">Progreso actual</h3>
          <LoadingBar
            bar={{
              actual: counterData?.evaluations ?? 0,
              from: 1,
              to: 1000,
              color: "blue",
              text: "Evaluaciones",
            }}
          />
        </section> */}
        {/* <section className="pt-2">
          <h3 className="text-xl font-bold text-secondary mb-2">
            Últimas evaluaciones
          </h3>
          <LastEvaluations />
        </section> */}

        <section className="pt-2">
          <h3 className="text-xl font-bold mb-2 ml-1 text-secondary">
            Preguntas frecuentes
          </h3>
          <div className="flex flex-wrap">
            {faq.map((q) => (
              <Disclosure
                key={q.key}
                as="div"
                className=" sm:w-1/2 w-full p-1"
                data-key={q.key}
              >
                {({ open }) => (
                  <>
                    <span onClick={() => (!open ? onFaqClick(q.key) : null)}>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left text-secondary bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="text-sm sm:text-base">{q.ask}</span>
                        <ChevronUpIcon
                          className={`${
                            open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                        />
                      </Disclosure.Button>
                    </span>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500 text-sm sm:text-base">
                      {q.response}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
