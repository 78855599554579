import { Route, Redirect } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { useLocation } from "react-router-dom";

export default function PrivateRoute({ component: Component, ...rest }: any) {
  const { status, data: auth } = useSigninCheck({
    initialData: false,
  });
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (status === "loading") {
          return (
            <div className="w-screen min-h-screen flex items-center justify-center">
              <svg
                className="animate-spin h-5 w-5 mr-3 bg-black text-black"
                viewBox="0 0 24 24"
              ></svg>
            </div>
          );
        }

        return auth?.signedIn ?? false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
}
