import { browserPopupRedirectResolver, FacebookAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

const provider = new FacebookAuthProvider();

provider.addScope("public_profile");
provider.addScope("email");

async function signInPopup() {

  const auth = getAuth();
  const credentials = await signInWithPopup(auth, provider)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      return credential
    })
    .catch((error) => {
      const credential = FacebookAuthProvider.credentialFromError(error);
      return credential
    });

  return credentials;
}

async function signInRedirect() {

  const auth = getAuth();
  const credentials = await signInWithPopup(auth, provider, browserPopupRedirectResolver)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      return credential
    })
    .catch((error) => {
      const credential = FacebookAuthProvider.credentialFromError(error);
      return credential
    });

  return credentials;
}


async function signIn() {
  await signInPopup()

}

export { signIn, signInPopup, signInRedirect };
