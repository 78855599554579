import { getAuth, OAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";
const provider = new OAuthProvider('apple.com');

provider.addScope('email');
provider.addScope('name');

async function signInPopup() {

  const auth = getAuth();
  const credentials = await signInWithPopup(auth, provider)
    .then((result) => {
      const credential = OAuthProvider.credentialFromResult(result);
      return credential
    })
    .catch((error) => {
      const credential = OAuthProvider.credentialFromError(error);
      return credential
    });

  return credentials;
}

async function signInRedirect() {

  const auth = getAuth();
  const credentials = await signInWithRedirect(auth, provider)
    .then((result) => {
      const credential = OAuthProvider.credentialFromResult(result);
      return credential
    })
    .catch((error) => {
      const credential = OAuthProvider.credentialFromError(error);
      return credential
    });

  return credentials;
}


async function signIn() {
  return await signInPopup()
}

export { signIn, signInPopup, signInRedirect };
