import { Teacher } from "@agendadorudg/models";
import { QuotaStatistics } from "@agendadorudg/models/dist/src/models/QuotaStatistics";
import { curveStepAfter } from "@visx/curve";
import {
  AnimatedAxis,
  Axis,
  LineSeries,
  Tooltip,
  XYChart,
  lightTheme,
} from "@visx/xychart";
import { Timestamp, doc, getDoc, getFirestore } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import { FIRESTORE_TEACHERS_STATS_COL } from "../../../constants";
import formattedDay from "../../../utils/formattedDay";

const accessors = {
  xAccessor: (d: any) => d && Date.parse(d.x),
  yAccessor: (d: any) => d && d.y,
};

export default function TeacherStatistics(props: { teacher: Teacher }) {
  const firestore = getFirestore();

  const teacher = props.teacher;

  const cycles = _.groupBy(teacher.quotaStatistics, (stat) => stat.cycle.id);
  var keys = Object.keys(cycles);
  keys = keys.sort((a, b) => parseInt(a) - parseInt(b));
  const firstCycle = cycles[keys[0]][0].cycle.id;

  const [cycle, setCycle] = useState<string>(firstCycle);
  // cycles[Object.keys(cycles)[0]][0].cycle.id

  // const firstSection = cycles[firstCycle][0]!;

  const [selectedSections, setSelectedSections] = useState<QuotaStatistics[]>(
    []
  );
  const [downloadedSections, setDownloadedSections] = useState<
    QuotaStatistics[]
  >([]);

  async function fetchStats(_sections: string[] = []) {
    const docs: QuotaStatistics[] = [];

    for (const i in _sections) {
      const _id = _sections[i];
      const docRef = doc(firestore, FIRESTORE_TEACHERS_STATS_COL, _id);
      const docSnap = await getDoc(docRef);
      const newQuota: QuotaStatistics = docSnap.data() as QuotaStatistics;
      newQuota.id = docSnap.id;
      docs.push(newQuota);
    }

    setDownloadedSections([...downloadedSections, ...docs]);
  }

  useEffect(() => {
    const dowloadedIds = downloadedSections.map((d) => d.id);
    const notDowloaded: QuotaStatistics[] = selectedSections.filter((s) => {
      return !dowloadedIds.includes(s.id);
    });

    const ids = notDowloaded.map((s) => s.id!);

    if (ids.length > 0) fetchStats(ids);

    //Remove from downloadedSections if not in selectedSections
    const newDownloadedSections = downloadedSections.filter((s) => {
      return selectedSections.map((s) => s.id).includes(s.id);
    });
    setDownloadedSections(newDownloadedSections);
  }, [selectedSections]);

  useEffect(() => {
    // setSelectedSections([firstSection]);
  }, []);

  function handleSelectClick(stat: QuotaStatistics) {
    if (selectedSections.includes(stat))
      setSelectedSections(selectedSections.filter((s) => s.id !== stat.id));
    else setSelectedSections([...selectedSections, stat]);
  }

  return (
    <div className="bg-white rounded-xl p-3 justify-center items-center">
      <p className="text-xl font-bold text-secondary">
        Gráficas de disponibilidad
      </p>
      <div className="my-4">
        <label htmlFor="cycle">Selecciona el ciclo: </label>
        <select
          id="cycle"
          name="cycle"
          className="bg-secondary bg-opacity-30 p-1 rounded-md"
          onChange={(e) => setCycle(e.target.value)}
        >
          {Object.keys(cycles).map((cycle) => (
            <option key={cycles[cycle][0].id} value={cycles[cycle][0].cycle.id}>
              {cycles[cycle][0].cycle.name}
            </option>
          ))}
        </select>
      </div>
      <div className="my-4">
        <label htmlFor="section">Selecciona las secciones: </label>
      </div>
      <div className="w-full overflow-scroll whitespace-nowrap">
        {cycle &&
          cycles[cycle]?.map((stat) => (
            <div
              key={stat.id}
              className={
                "inline-block align-top w-36 mr-3 p-3 mb-3 rounded-md border-secondary border-2" +
                (isMobile ? " cursor-pointer" : "") +
                (isMobile && selectedSections.includes(stat)
                  ? " bg-secondary bg-opacity-20"
                  : "")
              }
              onClick={() => isMobile && handleSelectClick(stat)}
            >
              <div key={stat.id + "_name"}>
                <span className="text-xs font-medium">Materia </span>
                <span
                  data-tip
                  data-for={stat.subject.name}
                  key={stat.subject.key + "_names"}
                  className="bg-primary text-secondary bg-opacity-40 text-opacity-80 px-2 py-1 rounded-md text-xs mr-1"
                >
                  {stat.subject.key}
                  <ReactTooltip
                    id={stat.subject.name}
                    place="top"
                    type="dark"
                    effect="solid"
                    className="text-white text-sm"
                  >
                    {stat.subject.name}
                  </ReactTooltip>
                </span>
              </div>
              <p key={stat.id + "---"}>
                <span className="text-xs font-medium">NRC </span>
                <span className="text-secondary text-xs">{stat.nrc}</span>
              </p>

              <p key={"horarios" + stat.id} className="text-xs font-medium">
                Horarios:
              </p>
              {stat.schedule.map((schedule) => (
                <p
                  key={stat.id + " " + stat.schedule.indexOf(schedule)}
                  className="text-xs text-secondary"
                >
                  {formattedDay(schedule.dia)} {schedule.inicio}-{schedule.fin}
                </p>
              ))}

              {isDesktop && (
                <div className="flex">
                  <p
                    className={
                      "p-1 px-3 mt-2 rounded-md text-sm bg-primary text-center cursor-pointer " +
                      (selectedSections.includes(stat) ? "bg-opacity-50" : "")
                    }
                    onClick={() => handleSelectClick(stat)}
                  >
                    {selectedSections.includes(stat)
                      ? "Seleccionado"
                      : "Seleccionar"}
                  </p>
                </div>
              )}
            </div>
          ))}
      </div>

      <XYChart
        height={300}
        theme={lightTheme}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
      >
        <Axis label="Hora en agenda" orientation="bottom" />
        <Axis label="Cupos disponibles" orientation="left" />
        <AnimatedAxis orientation="bottom" />
        {downloadedSections.length > 0 &&
          downloadedSections?.map((stat) => (
            <LineSeries
              curve={curveStepAfter}
              dataKey={`${stat.subject.key} ${stat.nrc} ${stat.cycle.id}`}
              data={stat.capacity!.map((quota) => ({
                x: (quota.date as unknown as Timestamp)?.toDate(),
                y: quota.capacity,
              }))}
              {...accessors}
              max={20}
            />
          ))}

        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          renderTooltip={({ tooltipData, colorScale }) => {
            const subjectKey = tooltipData!.nearestDatum!.key.split(" ")[0];
            const nrc = tooltipData!.nearestDatum!.key.split(" ")[1];
            const date =
              tooltipData!.nearestDatum &&
              accessors.xAccessor(tooltipData!.nearestDatum!.datum);
            const quota =
              tooltipData!.nearestDatum &&
              accessors.yAccessor(tooltipData!.nearestDatum!.datum);
            const _date = tooltipData!.nearestDatum && new Date(date!);
            return (
              <div className="font-normal">
                <div
                  className="font-bold"
                  style={{
                    color:
                      colorScale && colorScale(tooltipData!.nearestDatum!.key),
                  }}
                >
                  {subjectKey}
                </div>
                <p>
                  <span className="font-semibold">NRC: </span>
                  <span>{nrc}</span>
                </p>
                <p>
                  <span className="font-semibold">HORA: </span>
                  <span>
                    {tooltipData!.nearestDatum &&
                      _date!.toLocaleTimeString("en-US")}
                  </span>
                </p>
                <p>
                  <span className="font-semibold">CUPOS: </span>
                  <span>{quota}</span>
                </p>
              </div>
            );
          }}
        />
      </XYChart>
    </div>
  );
}
