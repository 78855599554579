import { Teacher } from "@agendadorudg/models";
import { getFirestore } from "@firebase/firestore";
import { Tab } from "@headlessui/react";
import { doc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useFirestoreDocDataOnce } from "reactfire";
import CampusPills from "../../components/CampusPills";
import SubjectsPills from "../../components/SubjectsPills";
import { FIRESTORE_TEACHERS_COL } from "../../constants";
import TeacherEvaluations from "./components/TeacherEvaluations";
import TeacherStatistics from "./components/TeacherStatistics";

export default function TeacherPage() {
  // Todo: changes needed to do from standard react
  const { teacherId } = useParams<{ teacherId?: string }>();
  const firestore = getFirestore();

  const { status: statusDoc, data: teacherDoc } = useFirestoreDocDataOnce(
    doc(firestore, `${FIRESTORE_TEACHERS_COL}/${teacherId}`)
  );

  if (statusDoc === "loading")
    return (
      <div className="flex justify-center items-center w-full text-center min-h-screen transform">
        <div>Cargando...</div>
      </div>
    );

  const teacher: Teacher = new Teacher(teacherDoc);
  teacher.id = teacherDoc.NO_ID_FIELD;

  return (
    <>
      <div className="min-h-screen w-full xl:px-72 lg:px-44 md:px-24 px-8 mt-3 sm:mt-10 md:mt:14 lg:mt-18 mb-4">
        <div>
          <p>Maestrx</p>
          <h1 className="w-full font-semibold text-secondary text-xl sm:text-2xl md:text-3xl mb-1">
            {teacher.name}
          </h1>
          <CampusPills className="mb-4" campus={teacher.campus ?? []} />
          <SubjectsPills className="mb-4" subjects={teacher.subjects ?? []} />
          <Tab.Group defaultIndex={teacher.evaluations === 0 ? 1 : 0}>
            <Tab.List className="flex p-1 space-x-1 bg-secondary w-full rounded-xl transition duration-200 mb-5">
              <Tab
                className={({ selected }) =>
                  "w-full py-2.5 text-sm leading-5 font-medium text-gray-200 rounded-lg transition " +
                  (selected ? "shadow-md bg-primary text-secondary" : "")
                }
              >
                Evaluaciones
              </Tab>
              <Tab
                className={({ selected }) =>
                  "w-full py-2.5 text-sm leading-5 font-medium text-gray-200 rounded-lg transition " +
                  (selected ? "shadow-md bg-primary text-secondary" : "")
                }
              >
                Estadísticas
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel>
                <TeacherEvaluations teacher={teacher} />
              </Tab.Panel>
              <Tab.Panel>
                {teacher.quotaStatistics!.length > 0  ? (
                  <TeacherStatistics teacher={teacher} />
                ) : (
                  <div className="flex justify-center items-center w-full text-center h-44 transform">
                    <div>No hay estadísticas disponibles</div>
                  </div>
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>

      <div className="flex justify-center items-center mb-4">
        <div className="text-xs text-gray-500 text-center">
          Icons made by{" "}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{" "}
          and{" "}
          <a href="https://www.flaticon.com/authors/monkik" title="monkik">
            monkik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </div>
    </>
  );
}
