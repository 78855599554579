import { Evaluation, Teacher } from "@agendadorudg/models";
import { logEvent } from "@firebase/analytics";
import { addDoc, collection, serverTimestamp } from "@firebase/firestore";
import { SearchIcon } from "@heroicons/react/solid";
import { getDocs, limit, query, where } from "firebase/firestore";
import { useState } from "react";
import {
  Configure,
  InstantSearch,
  connectAutoComplete
} from "react-instantsearch-dom";
import { useAnalytics, useAuth, useFirestore } from "reactfire";
import TeachersDropdown from "../../components/DropdownTeachers";
import EvaluateTeacherCard from "../../components/EvaluateTeacherCard";
import { SEOPage } from "../../components/SeoPage";
import { ALGOLIA_INDEX, FIRESTORE_EVALUATIONS_COL } from "../../constants";
import algoliaClient from "../../services/algolia";
import logEventNames from "../../services/analytics";
import "./index.css";

export default function EvaluatePage() {
  const [teachers, setTeachers] = useState<Teacher[]>();

  const Autocomplete = connectAutoComplete(
    ({ hits, currentRefinement, refine }) => (
      <div className="">
        <label
          htmlFor="search-teacher"
          className="relative text-gray-400 focus-within:text-gray-600 block"
        >
          <SearchIcon className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3" />
          <input
            name="search-teacher"
            type="search"
            disabled={(teachers?.length ?? 0) > 20}
            value={currentRefinement}
            className="pl-12 w-full md:w-10/12 lg:w-3/5 p-2 border rounded-md outline-none sm:text-xl focus:outline-none focus:ring-2 focus:ring-primary text-secondary"
            placeholder="Buscar maestro a evaluar"
            onChange={(event) => {
              if ((teachers?.length ?? 0) > 20) return;
              refine(event.currentTarget.value);
            }}
          />
        </label>
        {(teachers?.length ?? 0) > 20 && (
          <p className="mt-2 text-xs text-red-700">
            Sólo se pueden evaluar 20 maestros a la vez.
          </p>
        )}
        {(hits?.length ?? 0) > 0 && (
          <TeachersDropdown
            teachers={
              !currentRefinement
                ? []
                : hits.map((t) => {
                    const _t = new Teacher(t);
                    _t.id = t.objectID;
                    return _t;
                  })
            }
            onSelectTeacher={(teacher: Teacher) => {
              setTeachers([...(teachers ?? []), teacher]);
              refine("");
            }}
          />
        )}
      </div>
    )
  );

  const firestore = useFirestore();
  const auth = useAuth();
  const analytics = useAnalytics();

  async function evaluateTeacher(evaluation: Evaluation) {
    const docs = await getDocs(
      query(
        collection(firestore, FIRESTORE_EVALUATIONS_COL),
        where("user.id", "==", auth.currentUser?.uid),
        where("teacher.id", "==", evaluation.teacher?.id),
        where("modality", "==", evaluation.modality),
        limit(1)
      )
    );
    if (docs.size > 0) {
      alert("Ya haz evaluado a este maestro.");
      return;
    }

    await addDoc(collection(firestore, FIRESTORE_EVALUATIONS_COL), {
      ...evaluation,
      date: serverTimestamp(),
      user: {
        id: auth.currentUser?.uid,
      },
      verified: false,
    });
    const { evaluation: eventName } = logEventNames;
    logEvent(analytics, eventName);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 2000);
    });
  }

  return (
    <>
      <SEOPage
        title="Evaluar maestros"
        description="Herramienta tipo buscador de maestros para evaluarlos."
      />
      <div className="">
        <InstantSearch searchClient={algoliaClient} indexName={ALGOLIA_INDEX}>
          <Configure
            facets={["objectID", "campus:CUCEI"]}
            facetsExcludes={{
              objectID: teachers?.map((t) => t.id) ?? [],
            }}
          />
          <div className="min-h-screen w-full xl:px-72 lg:px-44 md:px-24 px-8 mb-8">
            <div className="space-y-4 mt-3 md:mt-8 lg:mt-10 mb-4">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-secondary tracking-tight">
                Evaluar a mis maestros
              </h1>
              <p className="sm:text-lg w-full sm:w-4/5">
                Busca a tus maestros y evalualos, fácil y rápido. Si no
                recuerdas su nombre completo, puedes escribir la materia seguido
                de su nombre.
              </p>
              <div>
                <Autocomplete />
                <div className="my-8">
                  <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-secondary tracking-tight">
                    {(teachers?.length ?? 0) === 0 ? "" : "Evaluando"}
                  </h2>
                  <div
                    id="evaluating-teachers"
                    className="grid md:grid-cols-2 grid-cols-1 my-4"
                  >
                    {teachers?.map((t: Teacher) => (
                      <EvaluateTeacherCard
                        key={t.id}
                        teacher={t}
                        onEvaluatedTeacher={evaluateTeacher}
                        onRemoveTeacher={async function () {
                          setTeachers(teachers.filter((item) => item !== t));
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
    </>
  );
}
