import { Evaluation } from "@agendadorudg/models";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  serverTimestamp,
  where,
} from '@firebase/firestore';
import { useState } from 'react';

import ReactTooltip from "react-tooltip";
import { useSigninCheck } from "reactfire";
import {
  DifficultyIcon,
  KnowledgeIcon,
  PunctualityIcon,
} from "../assets/EvaluationIcons";
import { FIRESTORE_REPORTS_COL } from "../constants";
import formatedDate from "../utils/formattedDate";

export default function EvaluationCard(props: any) {
  const evaluation: Evaluation = props.evaluation;
  // // const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const firestore = getFirestore();
  const { data: auth } = useSigninCheck({
    initialData: false,
  });

  const [reported, setReported] = useState(false);

  const reportEvaluation = async () => {
    // try {
    const docs = await getDocs(
      query(
        collection(firestore, FIRESTORE_REPORTS_COL),
        where("user.id", "==", auth.user?.uid),
        where("data.id", "==", evaluation.id),
        limit(1)
      )
    );
    if (docs.size === 1) return setReported(true);

    const newReport = {
      data: { id: evaluation.id, reason: "" },
      date: serverTimestamp(),
      type: "EVALUATION",
      user: {
        id: auth.user?.uid,
      },
    };

    await addDoc(collection(firestore, FIRESTORE_REPORTS_COL), newReport);

    setReported(true);
    // } catch (e) {
    //   console.log(
    //     "Ha habido un error al generar el reporte. Intentelo de nuevo:",
    //     e
    //   );
    // }
  };

  return (
    <div
      key={evaluation.user?.id}
      className="mb-3 relative p-3 transition duration-150 rounded-md hover:bg-gray-50 break-inside"
    >
      <div>
        <div className="w-full flex justify-between items-center mb-1">
          <h4 className="text-xs text-yellow-500">Evaluación</h4>
          <h4 className="text-xs">{formatedDate(evaluation.date)}</h4>
        </div>
        <h3 className="w-full font-medium text-sm sm:text-base leading-5 mb-3">
          {evaluation.comment}
        </h3>
      </div>
      <div>
        <div className="flex flex-wrap">
          <div className="mb-3 mr-5">
            <h4 className="text-xs text-gray-400">Modalidad</h4>
            <div className="mt-1">
              <span className="px-2 py-1 text-secondary bg-primary bg-opacity-50 rounded-md text-sm">
                {evaluation.modality === "CLASSROOM_BASED"
                  ? "PRESENCIAL"
                  : evaluation.modality}
              </span>
            </div>
          </div>
          <div className="mb-2">
            <h4 className="text-xs text-gray-400">Calificaciones</h4>
            <div className="flex flex-wrap space-x-5">
              <span
                data-tip
                data-for={`knowledge-${evaluation.user?.id}`}
                className="flex items-center mt-1 space-x-1 text-xs"
              >
                <KnowledgeIcon className="h-6 w-6" />
                <span className="text-lg">
                  {evaluation.knowledge}
                  <span className="text-xs">/10</span>
                </span>
                <ReactTooltip
                  id={`knowledge-${evaluation.user?.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <p className="text-white">Conocimiento</p>
                </ReactTooltip>
              </span>
              <span
                data-tip
                data-for={`punctuality-${evaluation.user?.id}`}
                className="flex items-center mt-1 space-x-1 text-xs"
              >
                <PunctualityIcon className="h-6 w-6" />
                <span className="text-lg">
                  {evaluation.punctuality}
                  <span className="text-xs">/10</span>
                </span>
                <ReactTooltip
                  id={`punctuality-${evaluation.user?.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <p className="text-white">Puntualidad</p>
                </ReactTooltip>
              </span>
              <span
                data-tip
                data-for={`difficulty-${evaluation.user?.id}`}
                className="flex items-center mt-1 space-x-1 text-xs"
              >
                <DifficultyIcon className="h-6 w-6" />
                <span className="text-lg">
                  {evaluation.difficulty}
                  <span className="text-xs">/10</span>
                </span>
                <ReactTooltip
                  id={`difficulty-${evaluation.user?.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <p className="text-white">Dificultad</p>
                </ReactTooltip>
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center">
          {auth.signedIn && (
            <button
              className="text-xs px-2 py-1 rounded-md transition hover:bg-secondary hover:bg-opacity-5"
              disabled={reported}
              onClick={reportEvaluation}
            >
              {reported ? "Reportado" : "Reportar"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
