/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import {
  Disclosure /*  Menu, Transition  */,
  Transition,
} from "@headlessui/react";
import { /* BellIcon ,*/ MenuIcon, XIcon } from "@heroicons/react/outline";
import { /* Fragment, */ useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import ProfileIcon from "./ProfileIcon";
import SignInPopUp from "./SignInPopup";

const navigation = [
  { name: "Inicio", href: "/" },
  { name: "Buscador", href: "/buscador" },
  // { name: "Blog", href: "/guias" },
  // { name: "Contribuir", href: "/contribuir" },
];

export default function Navbar() {

  useEffect(() => {
    const navbar = document.querySelector("#nav")!;

    window.onscroll = function () {
      if (window.scrollY > 80) {
        navbar.classList.add("shadow");
      } else {
        navbar.classList.remove("shadow");
      }
    };
  }, []);
  const [routes, setRoutes] = useState(navigation);
  const { status, data: auth } = useSigninCheck();

  useEffect(() => {
    setRoutes(
      auth?.signedIn ?? false
        ? [...navigation, { name: "Evaluar", href: "/evaluar" }]
        : navigation
    );
  }, [auth?.signedIn]);
  return (
    <Disclosure
      as="nav"
      id="nav"
      className="w-screen backdrop-filter backdrop-blur bg-white bg-opacity-50 sticky top-0 z-50"
    >
      {({ open, close }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white transition duration-200">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src="/logo192.png"
                    alt="Workflow"
                  />
                  <div className="hidden lg:inline-flex lg:items-center ">
                    <img
                      className="h-8 w-auto"
                      src="/logo192.png"
                      alt="Workflow"
                    />
                    <span className="ml-3 text-gray-900 font-bold tracking-tight">
                      Agendador UDG
                    </span>
                  </div>
                </div>
                <div className="hidden sm:block sm:ml-6 lg:mx-auto">
                  <div className="flex space-x-4">
                    {routes.map((item) => (
                      <NavLink
                        exact
                        key={item?.name}
                        to={item?.href}
                        activeClassName="bg-secondary text-white"
                        className="transition duration-150 px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-20 hover:bg-secondary focus:bg-opacity-100"
                      >
                        {item?.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>

              {status === "success" && auth?.signedIn ? (
                <ProfileIcon />
              ) : (
                <SignInPopUp />
              )}

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <Transition
              show={open}
              className="px-2 pt-2 pb-3 space-y-1 shadow-md transition duration-100"
            >
              {routes.map((item) => (
                <NavLink
                  exact
                  key={item.name}
                  to={item.href}
                  onClick={() => close()}
                  className="transition block px-3 py-2 rounded-md text-base font-medium"
                  activeClassName="transition bg-secondary text-white"
                >
                  {item.name}
                </NavLink>
              ))}
            </Transition>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
