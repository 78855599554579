import { browserPopupRedirectResolver, getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";

const provider = new GoogleAuthProvider();

async function signInPopup() {
  const auth = getAuth();
  const credentials = await signInWithPopup(auth, provider);

  return credentials;
}

async function signInRedirect() {
  const auth = getAuth();
  const credentials = await signInWithRedirect(auth, provider, browserPopupRedirectResolver);

  return credentials;
}

async function signIn() {
  return await signInPopup()
}

export { signIn, signInPopup, signInRedirect };
