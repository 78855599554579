const APP_CHECK_TOKEN = "6Ldg40McAAAAAFO_cuRuDIVAfGMRBCz9MLM1y7Q9";

const ALGOLIA_APP = "6HACVLHJWY";
const ALGOLIA_TOKEN = "b12e1c6e2b78ad864deafcbdecb2e03b";
const ALGOLIA_INDEX = "teachers";
const FIRESTORE_EVALUATIONS_COL = "evaluations";
const FIRESTORE_REPORTS_COL = "reports";
const FIRESTORE_TEACHERS_COL = "teachers";
const FIRESTORE_TEACHERS_STATS_COL = "quota_statistics";
const FIRESTORE_TEACHERS_COUNT = "configurations/counters";
const FIRESTORE_CAMPUS = "configurations/campus";
const LIMIT_COMMENT_EVALUATION_LENGTH = 450;

const LAST_EVALUATIONS_BUNDLE = "latest-evaluations";
const LAST_EVALUATIONS_QUERY = "latest-evaluations-query";

export { APP_CHECK_TOKEN, ALGOLIA_APP, ALGOLIA_TOKEN, ALGOLIA_INDEX, FIRESTORE_EVALUATIONS_COL, FIRESTORE_TEACHERS_COL, FIRESTORE_TEACHERS_COUNT, LIMIT_COMMENT_EVALUATION_LENGTH, LAST_EVALUATIONS_BUNDLE, LAST_EVALUATIONS_QUERY, FIRESTORE_REPORTS_COL, FIRESTORE_TEACHERS_STATS_COL, FIRESTORE_CAMPUS };

