import { Subject } from "@agendadorudg/models";
import ReactTooltip from 'react-tooltip';

export default function TeacherPills({ campus, subjects, ...rest }: any) {
  return (
    <div {...rest}>
      <div className="flex flex-wrap space-y-1 items-baseline">
        {campus?.map((c: string) => (
          <span
            key={c}
            className="bg-secondary bg-opacity-20 text-secondary  px-2 py-1 rounded-md text-xs mr-1"
          >
            {c}
          </span>
        ))}
        {subjects?.map((s: Subject) => (
          <span
            data-tip
            data-for={s.name}
            key={s.key}
            className="bg-primary bg-opacity-40 text-gray-700 text-opacity-80 px-2 py-1 rounded-md text-xs mr-1"
          >
            {s.key}
            <ReactTooltip id={s.name} place="top" type="dark" effect="solid">
              <p className="text-white">{s.name}</p>
            </ReactTooltip>
          </span>
        ))}
      </div>
    </div>
  );
}
