import { Campus, Teacher } from "@agendadorudg/models";
import {
  EmojiHappyIcon,
  EmojiSadIcon
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import {
  Configure,
  InstantSearch,
  Pagination,
  ScrollTo,
  connectHits,
  connectSearchBox,
  connectStateResults,
  connectStats
} from "react-instantsearch-dom";
import { useHistory, useLocation } from "react-router-dom";
import { SEOPage } from "../../components/SeoPage";
import TeacherCard from "../../components/TeacherCard";
import TeacherCardLoader from "../../components/TeacherCardLoader";
import { ALGOLIA_INDEX } from "../../constants";
import algoliaClient from "../../services/algolia";
import "./index.css";

// import { stringify } from "querystring";

export default function SearcherPage(props: any) {
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const searchQuery: string = query.get("q") || "";
  const campusQuery: string = query.get("campus") || localStorage.getItem("campus") ||  "";


  const [actualCampus, setActualCampus] = useState(campusQuery);

  const handleCardClick = (teacher: Teacher) => {
    props.history.push(`/maestro/${teacher.id}`);
  };

  const onSearch = function (currentSearch: string) {
    const campusInput = document.getElementById(
      "campus-input"
    ) as HTMLInputElement;
    const campusText = campusInput?.value.trim();

    const searchInput = document.getElementById(
      "search-input"
    ) as HTMLInputElement;
    const searchText = searchInput?.value.trim();

    if (
      campusText &&
      searchText &&
      (currentSearch != searchText ||
      campusText != actualCampus) 
    ) {
      if(actualCampus != campusText) {
        localStorage.setItem("campus", campusText);
        setActualCampus(campusText);
      }
      query.set("q", searchText!);
      if(campusText) query.set("campus", campusText!);
      history.replace(location.pathname + "?" + query.toString());
    }
  };
  const [campus, setCampus] = useState<Campus[]>([]);
  
  // const firestore = getFirestore();
  // const docRef = doc(firestore, FIRESTORE_CAMPUS);
  // const {  data, isComplete, error} = useFirestoreDocOnce(docRef);
  
  // useEffect(() => {
  //   if(!error && isComplete) {
  //     const _campus: Array<Campus> = data.data()!["campus"];
  //     const active = _campus.filter((campus) => campus.searcher ?? false);
  //     setCampus(active);
  //   }
    
  // }, [isComplete]);
  
  useEffect(()=>{
    setCampus([
      {
        id: 'CUCEI',
        shortName:"CUCEI",
        name:'CUCEI'
      },
    ])
  },[])

  const SearchBox = connectSearchBox(
    ({ currentRefinement }: any) => {

      return (
        <form action="" role="search">
          <div className="flex w-full lg:w-4/5 xl:w-4/5 2xl:w-11/12 max-w-2xl border-2 rounded-md outline mb-4 text-xl">
            <select
              id="campus-input"
              required 
              className="h-full inline-flex items-center py-3 px-4 text-center text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200 border-r" 
              defaultValue={campusQuery} 
              placeholder="Campus" 
              name="campus">
            {!actualCampus && <option value={campusQuery} disabled>{campusQuery}</option>}
              {campus.map((_campus: Campus) => (
                <option key={_campus.id} className="text-secondary flex items-center w-full px-2 py-2" value={_campus.shortName}>
                  {_campus.shortName}
                </option>
              ))}
            </select>

          <div className="flex w-full">
            <input 
            id="search-input" 
            type="search" 
            name="q" 
            defaultValue={searchQuery}
            className="w-full inline-flex py-2.5 px-4" 
            placeholder="Buscador de maestros" 
            required />
            <button 
            className="flex justify-center items-center p-2.5 font-medium text-secondary bg-primary"
            onClick={(event: any) => {
              event.preventDefault();
              onSearch(currentRefinement);
            }}
            >
              <span className="ml-1">Buscar</span>
            </button>
          </div>

        </div>
      </form>
      );
    }
  );

  const Hits = connectHits(({ hits }) => {
    return (
      <div className="masonry-col-1 sm:masonry-col-2 w-full">
        {hits.map((hit: any) => {
          const teacher: Teacher = new Teacher(hit);
          teacher.id = hit.objectID;
          return (
            <div key={teacher.id} className="px-1 py-1 items-start">
              <TeacherCard
                key={teacher.id}
                teacher={teacher}
                onClick={() => handleCardClick(teacher)}
              />
            </div>
          );
        })}
      </div>
    );
  });

  const Stats = connectStats(({ processingTimeMS, nbHits }) =>
    nbHits > 0 ? (
      <div className="text-xs ml-2 flex items-center mt-3">
        <div className="hover:text-pink-900 hover:rotate-90">
          <EmojiHappyIcon className="w-5 h-5 animate-bounce ease-in " />
        </div>
        <span className="ml-2">
          Encontramos {nbHits.toLocaleString()} resultados en{" "}
          {processingTimeMS.toLocaleString()} ms
        </span>
      </div>
    ) : null
  );

  const Results = connectStateResults(
    ({  searchResults, children, error, searching }: any) => {
      const hasResults = searchResults && searchResults.nbHits !== 0;

      if (error)
        <div className="flex justify-center items-center mt-32">
          <div className="block">
            <XIcon className="w-16 mx-auto text-gray-300" />
            <p className="text-gray-300">Ha ocurrido un grAVE ERROOOOOR</p>
          </div>
        </div>;

      // Loading
      if (searching)
        <div className="flex flex-wrap">
          <div className="px-1 py-1 w-full md:w-1/2 items-start">
            <TeacherCardLoader />
          </div>
          <div className="px-1 py-1 w-full md:w-1/2 items-start">
            <TeacherCardLoader />
          </div>
          <div className="px-1 py-1 w-full md:w-1/2 items-start">
            <TeacherCardLoader />
          </div>
          <div className="px-1 py-1 w-full md:w-1/2 items-start">
            <TeacherCardLoader />
          </div>
        </div>;

      // Not results
      if (!hasResults)
        return (
          <div className="flex justify-center items-center mt-32">
            <div className="block">
              <EmojiSadIcon className="w-16 mx-auto text-gray-300" />
              <p className="text-gray-300">No hemos encontrado nada</p>
            </div>
          </div>
        );

      // Results
      return children;
    }
  );
  
  return (
    <>
      <SEOPage
        title="Buscador de maestros"
        description="Ve las evaluaciones de tus maestros y elige la mejor opción"
      />
      <InstantSearch
        searchClient={algoliaClient}
        indexName={ALGOLIA_INDEX}
        refresh={false}
      >
        <Configure facetFilters={[`campus:${campusQuery}`]} />
        <div className="min-h-screen w-full xl:px-72 lg:px-44 md:px-24 px-8 mb-8">
          <div className="space-y-4 mt-3 md:mt-8 lg:mt-10 mb-4">
            <h1 className="text-5xl md:text-6xl font-bold text-secondary tracking-tight">
              Buscador
            </h1>
            <div>
              <ScrollTo />
              <SearchBox defaultRefinement={searchQuery}/>
              <Stats />
            </div>
          </div>
          <Results>
            <Hits />
          </Results>
        </div>
        <Pagination />
      </InstantSearch>
    </>
  );
}
