import algoliasearch from "algoliasearch/lite";
import { ALGOLIA_APP, ALGOLIA_TOKEN } from "../constants";


const algoliaClient = algoliasearch(ALGOLIA_APP, ALGOLIA_TOKEN);

const searchClient = {
  ...algoliaClient,
  search(requests: any) {

    if (requests.every(({ params }: any) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
        })),
      });
    }
    
    // Face the search requests
    return algoliaClient.search(requests);
  },
};

export default searchClient;
