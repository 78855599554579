// import { initializeAppCheck, ReCaptchaV3Provider } from "@firebase/app-check";
import { getAuth } from "@firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import React from "react";
import ReactDOM from "react-dom";
import {
  AnalyticsProvider,
  AppCheckProvider,
  // AppCheckProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";
import { APP_CHECK_TOKEN } from "./constants";
import "./index.css";
import AppRouter from "./router/AppRouter";
import { firebaseConfig } from "./services/firebase";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

function FirebaseApp() {
  const app = useFirebaseApp();

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  const auth = getAuth();
  const analytics = getAnalytics(app);
  const firestore = getFirestore(app);
  // const functions = getFunctions(app);

  // if (process.env.NODE_ENV !== "production") {
  //   connectFirestoreEmulator(firestore, "localhost", 8080);
  //   connectAuthEmulator(auth, "http://localhost:9099");
  //   connectFunctionsEmulator(functions, "localhost", 5001);
  // }

  return (
    <AppCheckProvider sdk={appCheck}>
      <AnalyticsProvider sdk={analytics}>
        <FirestoreProvider sdk={firestore}>
          <AuthProvider sdk={auth}>
            <AppRouter />
          </AuthProvider>
        </FirestoreProvider>
      </AnalyticsProvider>
    </AppCheckProvider>
  );
}

// ReactDOM.render(
//   <React.StrictMode>
//     <div className="flex justify-center items-center min-h-screen bg-secondary">
//       <h1 className="font-semibold text-primary">Proximamente</h1>
//     </div>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseApp />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
