import { Redirect, Route, useLocation } from "react-router-dom";
import { useUser } from "reactfire";

export default function PublicRoute({ component: Component, ...rest }: any) {
  const user = useUser();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        return user.data ? (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}
