import { logEvent } from "@firebase/analytics";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppleLoginButton,
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useAnalytics, useSigninCheck, useUser } from "reactfire";
import { signIn as signInApple } from "../../auth/apple";
import { signIn as signInFacebook } from "../../auth/facebook";
import { signIn as signInGoogle } from "../../auth/google";
import { SEOPage } from "../../components/SeoPage";
import "./index.css";

interface LocationState {
  from: {
    pathname: string;
  };
}

export default function LoginPage() {
  const { data: user } = useUser();
  const { data: auth } = useSigninCheck({
    initialData: false,
  });

  const analytics = useAnalytics();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const redirectLink = location.state?.from ?? "/";

  useEffect(() => {
    if (auth?.signedIn) {
      setTimeout(() => {}, 3);
      logEvent(analytics, "login");
      history.push(redirectLink);
    }
    // eslint-disable-next-line
  }, [auth?.signedIn]);

  return (
    <>
      <SEOPage
        title="Iniciar sesión"
        description="Inicia sesión en Agendador UDG para acceder a funcionalidades especiales."
      />
      <div
        id="background"
        className="bg-gradient-to-r from-sky-400 via-rose-400 to-lime-400"
      >
        <div className="h-screen flex flex-wrap items-center justify-center bg-gradient-to-r from-primary to-secondary via-yellow-600 background-animation">
          <div className="w-10/12 sm:w-2/4 md:w-96   shadow-md bg-gray-200 rounded-md ">
            <div className="px-6 sm:px-10 py-6 sm:py-10 flex flex-wrap justify-center">
              {auth?.signedIn ? (
                <>
                  <img
                    src={user?.photoURL!}
                    alt="profile"
                    className="rounded-full w-24 h-24 "
                  />
                  <p className="w-full mt-4 text-center">
                    Bienvenido{" "}
                    <span className="font-semibold">{user?.displayName}</span>
                  </p>
                  <div className="">
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    />
                    <p className="text-xs text-gray-500 mt-2">
                      Redireccionandote
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full flex h-12 items-start">
                    <img className="h-10 w-10" src="/logo192.png" alt="Icon" />
                    <div className="ml-2">
                      <h1 className="text-xs text-gray-500">Agendador UDG</h1>
                      <h1 className="text-lg font-bold">Iniciar sesión</h1>
                    </div>
                  </div>
                  <div className="space-y-2 py-8">
                    <FacebookLoginButton
                      onClick={signInFacebook}
                      className="text-xs"
                      text="Continuar con Facebook"
                    />
                    <GoogleLoginButton
                      onClick={signInGoogle}
                      className="text-xs"
                      text="Continuar con Google"
                    />
                    <AppleLoginButton
                      onClick={signInApple}
                      className="text-xs"
                      text="Continuar con Apple"
                    />
                    {/* <div
                      onClick={signInFacebook}
                      className="rounded-md px-4 py-1 text-sm md:text-base text-white border bg-blue-600 cursor-pointer"
                    >
                      Continuar con Facebook
                    </div> */}
                    {/* <div
                      onClick={signInGoogle}
                      className="rounded-md px-4 py-1 text-sm md:text-base text-black border bg-gray-50 cursor-pointer"
                    >
                      Continuar con Google
                    </div>
                    <div
                      onClick={signInApple}
                      className="rounded-md px-4 py-1 text-sm md:text-base text-gray-50 border bg-black cursor-pointer"
                    >
                      Continuar con Apple
                    </div> */}
                  </div>
                  <div className=" w-full">
                    <p className="text-sm mb-1">
                      ¿Por qué debo iniciar sesión?
                    </p>
                    <p className="text-xs text-gray-500">
                      Algunos servicios y funcionalidades tienen que ser
                      protegidos con autenticación para evitar problemas de
                      seguridad.
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
