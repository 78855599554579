import { Evaluation } from "@agendadorudg/models";
import { getDocsFromCache, loadBundle, namedQuery } from "firebase/firestore";
// import { getFunctions } from "@firebase/functions";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import { useFirestore } from "reactfire";
import {
  DifficultyIcon,
  KnowledgeIcon,
  PunctualityIcon,
} from "../assets/EvaluationIcons";
import { LAST_EVALUATIONS_QUERY } from "../constants";

export default function LastEvaluations() {
  const firestore = useFirestore();

  const [loadedLastEvaluations, setLoadedLastEvaluations] =
    useState<boolean>(false);

  async function loadLatestEvaluations() {
    const bundle = await fetch("https://agendadorudg.com/api/lastEvaluations");

    await loadBundle(firestore, bundle.body!);
    // Query the results from the cache
    // Note: omitting "source: cache" will query the Firestore backend.

    const query = await namedQuery(firestore, LAST_EVALUATIONS_QUERY);

    const snapshot = await getDocsFromCache(query!);
    const _evaluations = snapshot.docs.map((d) => {
      const _ev = new Evaluation(d.data());
      _ev.id = d.id;
      return _ev;
    });
    setEvaluations(_evaluations);
    setLoadedLastEvaluations(true);
  }

  useEffect(() => {
    loadLatestEvaluations();
  }, []);

  const history = useHistory();

  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  return (
    <>
      {!loadedLastEvaluations && (
        <div className="flex justify-center w-full">
          <div className="w-min my-12">Cargando...</div>
        </div>
      )}
      <div className="masonry-col-1 md:masonry-col-2 lg:masonry-col-3 before:box-inherit after:box-inherit">
        {evaluations.map((evaluation) => (
          <div key={evaluation.id} className="break-inside mt-2">
            <div className="p-3 border rounded-md border-primary space-y-2">
              <div className="flex flex-wrap items-center">
                <p className="text-xs text-gray-500">Maestro</p>
                <p
                  className="text-sm font-bold text-secondary ml-1 cursor-pointer"
                  onClick={() =>
                    history.push(`/maestro/${evaluation.teacher?.id}`)
                  }
                >
                  {evaluation.teacher?.name}
                </p>
              </div>
              <div className="mb-2 flex flex-wrap items-center">
                <h4 className="text-xs text-gray-500">Calificaciones</h4>
                <div className="flex flex-wrap space-x-5 ml-1">
                  <span
                    data-tip
                    data-for={`knowledge-${evaluation.user?.id}`}
                    className="flex items-center mt-1 space-x-1 text-xs"
                  >
                    <KnowledgeIcon className="h-6 w-6" />
                    <span className="text-lg">
                      {evaluation.knowledge}
                      <span className="text-xs">/10</span>
                    </span>
                    <ReactTooltip
                      id={`knowledge-${evaluation.user?.id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <p className="text-white">Conocimiento</p>
                    </ReactTooltip>
                  </span>
                  <span
                    data-tip
                    data-for={`punctuality-${evaluation.user?.id}`}
                    className="flex items-center mt-1 space-x-1 text-xs"
                  >
                    <PunctualityIcon className="h-6 w-6" />
                    <span className="text-lg">
                      {evaluation.punctuality}
                      <span className="text-xs">/10</span>
                    </span>
                    <ReactTooltip
                      id={`punctuality-${evaluation.user?.id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <p className="text-white">Puntualidad</p>
                    </ReactTooltip>
                  </span>
                  <span
                    data-tip
                    data-for={`difficulty-${evaluation.user?.id}`}
                    className="flex items-center mt-1 space-x-1 text-xs"
                  >
                    <DifficultyIcon className="h-6 w-6" />
                    <span className="text-lg">
                      {evaluation.difficulty}
                      <span className="text-xs">/10</span>
                    </span>
                    <ReactTooltip
                      id={`difficulty-${evaluation.user?.id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <p className="text-white">Dificultad</p>
                    </ReactTooltip>
                  </span>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">Evaluación</p>
                <p className="text-sm font-medium italic mt-1">
                  <span>"{evaluation.comment}"</span>
                  <span className="text-sm mt-2 ml-3 text-gray-500">
                    -Anónimo
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
