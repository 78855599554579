/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const supportForm = 'https://docs.google.com/forms/d/e/1FAIpQLSfya-xSwq-Jz9yiOfWbtfQusit8pu8F0etQBZVOPCLQE2iv_w/viewform?embedded=true';

export default class SupportPage extends React.Component {

  componentDidMount() {
    document.title = 'Soporte';
  }

  render() {
    return (
      <div>
        <iframe src={supportForm} className="h-screen w-screen m-0 p-0 border-0">Cargando…</iframe>
      </div>
    );
  }
}