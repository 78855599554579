import { Evaluation, Teacher } from "@agendadorudg/models";

import { Timestamp, collection, getFirestore, limit, orderBy, query, where } from "@firebase/firestore";

import { useFirestoreCollectionData } from "reactfire";
import EvaluationCard from "../../../components/EvaluationCard";
import { FIRESTORE_EVALUATIONS_COL } from "../../../constants";
import ButtonEvaluate from "./ButtonEvaluate";

export default function TeacherEvaluations(props: { teacher: Teacher }) {
  const firestore = getFirestore();

  const teacher = props.teacher;
  const teacherId = teacher.id;

  // --------- Evaluations --------- //
  const evaluationsQuery = query(
    collection(firestore, FIRESTORE_EVALUATIONS_COL),
    where("teacher.id", "==", teacherId),
    orderBy("date", "desc"),
    limit(10)
  );

  const { status, data: evaluations } =
    useFirestoreCollectionData(evaluationsQuery);

  if (status === "loading")
    return (
      <div className="flex justify-center items-center w-full text-center min-h-screen transform">
        <div>Cargando...</div>
      </div>
    );

  const onEvaluation = (evaluation: Evaluation) => {
    evaluations.concat({ ...evaluation });
  };

  return (
    <div
      className={
        "bg-white rounded-md " +
        (evaluations.length === 0
          ? "masonry-col-1"
          : "masonry-col-1 sm:masonry-col-2")
      }
    >
      {evaluations.map((_evaluation: any) => {
        const evaluation = new Evaluation({
          ..._evaluation,
          date: (_evaluation.date as Timestamp)?.toDate(),
        });
        evaluation.id = _evaluation.NO_ID_FIELD;

        return <EvaluationCard key={evaluation.id} evaluation={evaluation} />;
      })}

      <ButtonEvaluate
        teacher={teacher}
        onEvaluation={onEvaluation}
        empty={evaluations.length === 0}
      />
    </div>
  );
}
