import { logEvent } from "@firebase/analytics";
import { Popover } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { useAnalytics } from "reactfire";

// async function onAppleLogin() {
//   const signIn = await import("../auth/apple");
//   signIn.default();
// }
export default function SignInPopUp() {
  const analytics = useAnalytics();
  const onClick = async () => {
    const logEventNames = require("../services/analytics");
    const { signIn_click: eventName } = logEventNames;
    logEvent(analytics, eventName);
  };
  const location = useLocation();
  return (
    <Popover className="fixed right-4 top-4">
      {({ open }) => (
        <>
          <Link
            to={{ pathname: "/login", state: { from: location } }}
            onClick={onClick}
            className={`
                ${open ? "" : "text-opacity-90"}
                text-sm sm:text-base text-black group rounded-md inline-flex items-center  font-medium hover:text-opacity-100 focus:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-opacity-75 bg-primary px-2 py-1`}
          >
            <span>Iniciar sesión</span>
          </Link>
        </>
      )}
    </Popover>
  );
}
