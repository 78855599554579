export default function CampusPills({ campus, ...rest }: any) {
  return (
    <div {...rest}>
      <div className="flex flex-wrap space-y-1 items-baseline">
        {campus?.map((c: string) => (
          <span
            key={c}
            className="bg-secondary text-secondary bg-opacity-20  px-2 py-1 rounded-md text-xs mr-1"
          >
            {c}
          </span>
        ))}
      </div>
    </div>
  );
}
