const logEventNames = {
  // Click on Login Nav Button
  signIn_click: "login_click",
  // What page is seen
  page_view: "page_view",
  // Click on evaluate button
  evaluate_click: "evaluate_click",
  login_to_evaluate_teacher: "login_to_evaluate",
  // Login event
  login: "login",

  faq_click: "faq_click",
  contribute_click: "contribute_click",

  evaluation: "evaluation",
};

export default logEventNames;
