import { Timestamp } from "@firebase/firestore";

export default function formatedDate(date?: Date) {
  const now = Timestamp.now().toDate()

  if (date?.toDateString() === now.toDateString())
    return `${date.getHours()}:${date.getMinutes()}`
  return date?.toLocaleDateString();
}


