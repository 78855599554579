// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCKRjik5anZ-meOFexGYcFPnVa_PEvtGQ",
  authDomain: "agendadorudg.firebaseapp.com",
  projectId: "agendadorudg",
  storageBucket: "agendadorudg.appspot.com",
  messagingSenderId: "815807301984",
  appId: "1:815807301984:web:a5d3ffbf03f9f4e8ac7605",
  measurementId: "G-ST56N0XC0V",
};

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCIa_124Qdws10rGEoCW6OVFJGlwXF1sxM",
//   authDomain: "agendadorudg-test.firebaseapp.com",
//   databaseURL: "https://agendadorudg-test-default-rtdb.firebaseio.com",
//   projectId: "agendadorudg-test",
//   storageBucket: "agendadorudg-test.appspot.com",
//   messagingSenderId: "570588274082",
//   appId: "1:570588274082:web:07ed930b2a7833b0e526fb",
//   measurementId: "G-G9N6GEC5LD"
// };

// const app = firebase.initializeApp(firebaseConfig);

// const db = app.firestore();

export { firebaseConfig };
