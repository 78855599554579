/* eslint-disable react-hooks/exhaustive-deps */

import { logEvent } from "@firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAnalytics } from "reactfire";
import eventNames from "../services/analytics";

export default function PageLoggerAnalytics() {
  const analytics = useAnalytics();
  const location = useLocation();
  const { page_view: eventName } = eventNames;

  useEffect(() => {
    logEvent(analytics, eventName, {
      page_location: window.location.pathname,
    });
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
