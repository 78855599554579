import {
  BrowserRouter as Router, Redirect, Route,
  Switch
} from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PageLoggerAnalytics from "../components/PageLoggerAnalytics";
import LoginPage from "../pages/LoginPage";

import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import DeleteAccountPage from "../pages/DeleteAccount";
import EvaluatePage from "../pages/EvaluatePage";
import LandingPage from "../pages/LandingPage";

import EvaluationPage from "../pages/EvaluationPage";
import NotFoundPage from "../pages/NotFoundPage";
import PoliticsPage from "../pages/PoliticPage";
import SchedulePage from "../pages/SchedulePage";
import SearcherPage from "../pages/SearcherPage";
import SupportPage from "../pages/SupportPage";
import TeacherPage from "../pages/TeacherPage";

const iosLink = "https://apps.apple.com/mx/app/agendador-udg/id1546918559";
const andLink =
  "https://play.google.com/store/apps/details?id=com.diezep.agendadorudg";
const whatsappLink = "https://chat.whatsapp.com/LVH10zANzWr5sB5z8Cc3CQ";

function redirectTo(url: string): any {
  window.location.href = url;
}

export default function AppRouter() {
  return (
    <Router>
      <PageLoggerAnalytics />
      <Switch>
        <PublicRoute exact path="/login" component={LoginPage} />

        <Route path="*">
          <Navbar />

          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/soporte" component={SupportPage} />
            <Route path="/politicas" component={PoliticsPage} />
            <Route path="/eliminar-cuenta" component={DeleteAccountPage} />
            <Route path="/buscador" component={SearcherPage} />
            <Route path="/maestro/:teacherId" component={TeacherPage} />

            <Route path="/evaluaciones" component={EvaluationPage} />
            <Route path="/horario" component={SchedulePage} />
            <PrivateRoute path="/evaluar" component={EvaluatePage} />
            {/* iOS Downdload */}
            <Route path="/ios" component={() => redirectTo(iosLink)} />

            {/* Android Downdload */}
            <Route path="/android" component={() => redirectTo(andLink)} />

            {/* Whatsapp Group */}
            <Route
              path="/whatsapp"
              component={() => redirectTo(whatsappLink)}
            />

            <Redirect path="/buscar" to="/buscador" />

            {/* 404 Not Found */}
            <Route exact path="*" component={NotFoundPage} />
          </Switch>

          <Footer />

          <div id="modals"></div>
        </Route>
      </Switch>
    </Router>
  );
}
