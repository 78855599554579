import { SEOPage } from "../../components/SeoPage";

export default function NotFoundPage() {
  return (
    <>
      <SEOPage
        title="Página no encontrada :("
        description="Link caido o inválido"
      />
      <div className="container">
        <div className="flex h-screen w-screen justify-center items-center">
          <p>
            <b>404</b> Página no encontrada :(
          </p>
        </div>
      </div>
    </>
  );
}
