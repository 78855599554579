import { Link } from "react-router-dom";
import { useSigninCheck } from "reactfire";

function Footer() {
  const { status, data: auth } = useSigninCheck({ initialData: false });

  return (
    <footer className="w-screen bg-secondary items-start p-8 sm:grid-cols-2 grid grid-cols-1 md:grid-cols-3">
      <ul className="text-white break-normal block mr-8 mt-4">
        <h4 className="font-medium text-primary text-opacity-60 break-normal">
          Descargas
        </h4>
        <li>
          <Link className="hover:underline" to="/android">
            Android
          </Link>
        </li>
        <li>
          <Link className="hover:underline" to="/ios">
            iOS
          </Link>
        </li>
      </ul>
      <ul className="text-white break-normal block mt-4 mr-8">
        <h4 className="font-medium text-primary text-opacity-60 break-normal">
          Extras
        </h4>
        <li>
          <Link className="hover:underline" to="/politicas">
            Politicas de Privacidad
          </Link>
        </li>
        <li>
          <Link className="hover:underline" to="/soporte">
            Soporte
          </Link>
        </li>
        <li>
          <Link className="hover:underline" to="/eliminar-cuenta">
            Eliminar mi cuenta
          </Link>
        </li>
      </ul>
      <ul className="text-white break-normal block mt-4">
        <h4 className="font-medium text-primary text-opacity-60 break-normal">
          Sitemap
        </h4>
        <li>
          <Link className="hover:underline" to="/">
            Inicio
          </Link>
        </li>
        <li>
          <Link className="hover:underline" to="/buscador">
            Buscador
          </Link>
        </li>
        {status === "success" && auth.signedIn ? (
          <li>
            <Link className="hover:underline" to="/evaluar">
              Evaluador de maestros
            </Link>
          </li>
        ) : null}
      </ul>
    </footer>
  );
}

export default Footer;
