import { Teacher } from "@agendadorudg/models";
import { MouseEventHandler } from "react";
import ReactTooltip from "react-tooltip";
import {
  DifficultyIcon,
  KnowledgeIcon,
  PunctualityIcon
} from "../assets/EvaluationIcons";
import CampusPills from "./CampusPills";
import SubjectsPills from "./SubjectsPills";

export default function TeacherCard(props: {
  teacher: Teacher;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  return (
    <div className="relative hover:bg-gray-100 rounded-lg break-inside">
      {/* <div className="absolute bottom-1/2 right-4">
        <h1 className="text-7xl text-secondary dark:text-primary text-opacity-25">80</h1>
      </div> */}
      <div className="px-3 py-2 space-y-2 block">
        <div>
          <h4 className="text-base text-gray-800 font-medium  opacity-95">
            {props.teacher.name}
          </h4>
          <h5 className="text-xs rounded-md w-min text-secondary dark:text-primary">
            <CampusPills campus={props.teacher.campus ?? []} />
          </h5>
        </div>
        <div>
          <div className="mb-2">
            <h4 className="text-xs text-gray-500">Calificaciones</h4>
            <div className="flex flex-wrap space-x-2 sm:space-x-4 lg:space-x-5">
              <span
                data-tip
                data-for={`knowledge-${props.teacher.id}`}
                className="flex items-center mt-1 space-x-1 text-xs"
              >
                <KnowledgeIcon className="h-6 w-6" />
                <span className="text-xl">
                  {props.teacher.knowledge?.toFixed(
                    props.teacher.knowledge % 1 > 0 ? 1 : 0
                  ) ?? "--"}
                  <span className="text-xs">/10</span>
                </span>
                <ReactTooltip
                  id={`knowledge-${props.teacher.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <p className="text-white">Conocimiento</p>
                </ReactTooltip>
              </span>
              <span
                data-tip
                data-for={`punctuality-${props.teacher.id}`}
                className="flex items-center mt-1 space-x-1 text-xs"
              >
                <PunctualityIcon className="h-6 w-6" />
                <span className="text-xl">
                  {props.teacher.punctuality?.toFixed(
                    props.teacher.punctuality % 1 > 0 ? 1 : 0
                  ) ?? "--"}
                  <span className="text-xs">/10</span>
                </span>
                <ReactTooltip
                  id={`punctuality-${props.teacher.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <p className="text-white">Puntualidad</p>
                </ReactTooltip>
              </span>
              <span
                data-tip
                data-for={`difficulty-${props.teacher.id}`}
                className="flex items-center mt-1 space-x-1 text-xs"
              >
                <DifficultyIcon className="h-6 w-6" />
                <span className="text-xl">
                  {props.teacher.difficulty?.toFixed(
                    props.teacher.difficulty % 1 > 0 ? 1 : 0
                  ) ?? "--"}
                  <span className="text-xs">/10</span>
                </span>
                <ReactTooltip
                  id={`difficulty-${props.teacher.id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <p className="text-white">Dificultad</p>
                </ReactTooltip>
              </span>
            </div>
          </div>
        </div>
        <div className="subjects-pills">
          <SubjectsPills
            textColorClass="text-gray-900"
            backgroundColorClass="bg-secondary"
            subjects={props.teacher.subjects}
          />
        </div>
        {props.teacher.quotaStatistics!.length > 0 && (
          <div key="pill-statistics" className="flex">
            <h4 className="rounded-md text-xs text-secondary bg-opacity-90">
              Contiene estadísticas
            </h4>
          </div>
        )}
        <div className="pt-1 pb-1">
          <button
            onClick={props.onClick}
            className="bg-primary bg-opacity-90 text-secondary px-4 py-1 rounded-md"
          >
            Ver evaluaciones
          </button>
        </div>
      </div>
    </div>
  );
}
