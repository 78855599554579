import { Evaluation } from "@agendadorudg/models";
import { logEvent } from "firebase/analytics";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useAnalytics, useFirestore, useSigninCheck } from "reactfire";
import EvaluateTeacherCard from "../../../components/EvaluateTeacherCard";
import { FIRESTORE_EVALUATIONS_COL } from "../../../constants";
import logEventNames from "../../../services/analytics";

export default function ButtonEvaluate(props: any) {
  const firestore = useFirestore();
  const analytics = useAnalytics();
  const location = useLocation();

  const { teacher, onEvaluation, empty = false } = props;
  const [evaluate, setEvaluate] = useState(false);

  useEffect(() => {
    if (evaluate) {
      const y = document.getElementById("evaluate_card")!.offsetTop;
      window.scrollTo(0, y - 100);
    }
  }, [evaluate]);

  const { data: auth } = useSigninCheck({
    initialData: false,
  });

  const onClickLogin = async () => {
    const logEventNames = require("../../../services/analytics");
    const { login_to_evaluate_teacher: eventName } = logEventNames;
    logEvent(analytics, eventName);
  };

  async function evaluateTeacher(evaluation: Evaluation) {
    const docs = await getDocs(
      query(
        collection(firestore, FIRESTORE_EVALUATIONS_COL),
        where("user.id", "==", auth.user?.uid),
        where("teacher.id", "==", teacher.id),
        where("modality", "==", evaluation.modality),
        limit(1)
      )
    );
    if (docs.size > 0) {
      alert(
        "Sólo se permite una evaluación al mismo maestro en la misma modalidad :("
      );
      setEvaluate(false);
      return;
    }

    await addDoc(collection(firestore, FIRESTORE_EVALUATIONS_COL), {
      ...evaluation,
      date: serverTimestamp(),
      user: {
        id: auth.user?.uid,
      },
    });
    const { evaluation: eventName } = logEventNames;
    logEvent(analytics, eventName);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
        onEvaluation(evaluation);
      }, 2000);
    });
  }

  if (evaluate)
    return (
      <div
        id="evaluate_card"
        className="relative transition duration-150 rounded-md hover:bg-gray-50 break-inside"
      >
        <EvaluateTeacherCard
          teacher={teacher}
          headless={true}
          onEvaluatedTeacher={evaluateTeacher}
          onRemoveTeacher={() => setEvaluate(false)}
        />
      </div>
    );
  else
    return (
      <div
        className={
          "text-center flex justify-center items-center flex-wrap " +
          (empty ? "w-full mt-16" : "w-full")
        }
      >
        <div className="w-full mt-12">
          {empty && auth.signedIn ? (
            <p>Aún no hay evaluaciones de este maestro. Sé el primero 🥳</p>
          ) : auth.signedIn ? (
            <p>Apoya a tu comunidad estudiantil y evalúa a este maestro 🥳</p>
          ) : (
            <p>Inicia sesión y evalúa a este maestro 🥳</p>
          )}
        </div>
        {auth.signedIn ? (
          <button
            className={
              "m-2 text-secondary py-1 px-2 rounded text-lg bg-primary hover:opacity-80"
            }
            onClick={() => setEvaluate(true)}
          >
            Evaluar maestro
          </button>
        ) : (
          <Link
            to={{ pathname: "/login", state: { from: location } }}
            onClick={onClickLogin}
            className="mt-2 m-6 text-sm sm:text-base text-black group rounded-md inline-flex items-center  font-medium hover:text-opacity-100 focus:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-opacity-75 bg-primary px-2 py-1"
          >
            <span>Iniciar sesión</span>
          </Link>
        )}
      </div>
    );
}
