
import { Subject } from "@agendadorudg/models";
import ReactTooltip from "react-tooltip";
export default function SubjectsPills({
  subjects,
  backgroundColorClass,
  textColorClass,
  ...rest
}: any) {
  return (
    <div {...rest}>
      <div className="flex flex-wrap space-y-1 items-baseline">
        <p className="text-xs text-gray-700 opacity-70 mr-1">Materias </p>
        {subjects?.map((s: Subject) => (
          <span
            data-tip
            data-for={s.name}
            key={s.key}
            className={`${textColorClass ?? "bg-primary"} ${
              backgroundColorClass ?? "text-secondary"
            } bg-opacity-40 text-opacity-80 px-2 py-1 rounded-md text-xs mr-1`}
          >
            {s.key}
            <ReactTooltip id={s.name} place="top" type="dark" effect="solid">
              <p className="text-white">{s.name}</p>
            </ReactTooltip>          
          </span>
        ))}
      </div>
    </div>
  );
}
