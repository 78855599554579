import { logEvent } from "@firebase/analytics";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import {
  BadgeCheckIcon,
  CogIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  SaveIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useAnalytics } from "reactfire";
import LastEvaluations from "../../components/LastEvaluations";
import { SEOPage } from "../../components/SeoPage";
import logEventNames from "../../services/analytics";
import SearcherInput from "./components/SearcherInput";
import "./index.css";

function LandingPage() {
  const analytics = useAnalytics();

  async function handleContribute() {
    const { contribute_click: eventName } = logEventNames;
    logEvent(analytics, eventName);
    alert("Próximamente! :)");
  }

  return (
    <div>
      <SEOPage
        title="Agendador UDG"
        description="Buscador de maestros y generador de horarios"
      />
      <div className="App w-full bg-gradient-to-br from-white to-gray-100 flex justify-center">
        <div className="w-10/12 sm:w-9/12 md:w-9/12 lg:w-8/12 xl:w-7/12 max-w-6xl space-y-10">
          <section className="pb-8 pt-16 sm:pb-16 sm:pt-24 md:pt-30 md:pb-24">
            <h2 className="pt-6 md:text-left text-left mb-3 text-5xl sm:text-6xl text-secondary font-bold">
              Crear el{" "}
              <span className="text-primary opacity-75">horario ideal</span> para
              ti
            </h2>
            <p className="text-gray-800 text-xl sm:text-2xl mb-5 sm:w-8/12 md:w-4/5 xl:w-9/12 lg:w-4/5 w-full">
              Selecciona a tus maestros, selecciona tus horarios y genera el mejor
              horario para tu próximo semestre{" "}
            </p>
            <SearcherInput />
            <p className="text-gray-800 text-sm">
              Al hacer uso de Agendador UDG bla bla bla{" "}
              <Link to="/politicas" className="text-secondary underline">
                políticas de privacidad
              </Link>
              ...
            </p>
          </section>
          <section id="lastEvaluations" className="py-2">
            <h2 className="text-yellow-500">Evaluaciones</h2>
            <h1 className="text-3xl font-semibold mb-4">Últimas evaluaciones</h1>
            <LastEvaluations></LastEvaluations>
          </section>
          <section id="download" className="py-3">
            <h2 className="text-indigo-500">Descargas</h2>
            <h1 className="text-3xl font-semibold mb-4">Plataformas</h1>
            <div className="flex space-x-2 justify-center">
              <table className="w-full lg:w-5/6 xl:w-6/8 table-fixed border-collapse text-sm sm:text-lg">
                <thead>
                  <tr className="text-center">
                    <th className="w-6/12"></th>
                    <th className="w-2/12 rounded-tl-xl bg-secondary text-white font-normal">
                      Web
                    </th>

                    <th className="w-3/12 rounded-tr-xl bg-secondary text-white font-normal">
                      Aplicación
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className="border-bg-secondary border">
                    <td>Buscador de maestros</td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-bg-secondary border">
                    <td>Analizador maestros por materia</td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-bg-secondary border">
                    <td>Generador de horarios</td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <XIcon className="h-5 w-5 mr-1 mt-1 text-red-500" />
                      </div>
                    </td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-bg-secondary border">
                    <td>Creador de horarios</td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <XIcon className="h-5 w-5 mr-1 mt-1 text-red-500" />
                      </div>
                    </td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-bg-secondary border">
                    <td>Guardar horarios</td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <XIcon className="h-5 w-5 mr-1 mt-1 text-red-500" />
                      </div>
                    </td>
                    <td className="text-white text-center table-cell">
                      <div className="flex justify-center">
                        <BadgeCheckIcon className="h-5 w-5 mr-1 mt-1 text-green-500" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="space-x-2 space-y-3 flex flex-wrap my-4 justify-center items-start">
              <div></div>
              {/* <a
                    className="bg-white shadow-md px-3 py-2 rounded-lg flex items-center space-x-4"
                    href="/#"
                  >
                    <DesktopComputerIcon className="w-7" />
                    <div className="text">
                      <p className=" text-xs text-gray-600">Descargar versión</p>
                      <p className=" text-xs font-semibold text-gray-900">Beta</p>
                    </div>
                  </a> */}
              <a
                className="bg-white shadow-md px-3 py-2 rounded-lg flex items-center space-x-4"
                href="/ios"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  width="419.955"
                  height="512"
                  viewBox="0 0 419.955 512"
                >
                  <g transform="translate(-46.022)">
                    <path d="M185.255,512c-76.2-.439-139.233-155.991-139.233-235.21,0-129.4,97.075-157.734,134.487-157.734,16.86,0,34.863,6.621,50.742,12.48,11.1,4.087,22.588,8.306,28.975,8.306,3.823,0,12.832-3.589,20.786-6.738,16.963-6.753,38.071-15.146,62.651-15.146h.146c18.354,0,74,4.028,107.461,54.272l7.837,11.777-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267,0,52.031,33.3,72.041,49.292,81.665,7.061,4.248,14.37,8.628,14.37,18.208,0,6.255-49.922,140.566-122.417,140.566-17.739,0-30.278-5.332-41.338-10.034-11.191-4.761-20.845-8.862-36.8-8.862-8.086,0-18.311,3.823-29.136,7.881C221.5,505.73,204.752,512,185.753,512Z" />
                    <path d="M351.343,0c1.888,68.076-46.8,115.3-95.425,112.342C247.9,58.015,304.54,0,351.343,0Z" />
                  </g>
                </svg>
                <div className="text">
                  <p className=" text-xs text-gray-600">Descargar desde</p>
                  <p className=" text-xs font-semibold text-gray-900">
                    Apple Store
                  </p>
                </div>
              </a>
              <a
                className="bg-white shadow-md px-3 py-2 rounded-lg flex items-center space-x-4"
                href="/android"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  width="436.057"
                  height="469.847"
                  viewBox="0 0 436.057 469.847"
                >
                  <g transform="translate(-16.896)">
                    <path
                      d="M270.336,234.965,34.39,462.165A40.146,40.146,0,0,1,16.9,428.672V41.258A40.146,40.146,0,0,1,34.39,7.765Z"
                      fill="#2196f3"
                    />
                    <path
                      d="M352.9,155.6l-82.56,79.36L34.39,7.765a31.446,31.446,0,0,1,2.773-1.92A40.363,40.363,0,0,1,77.91,5.2Z"
                      fill="#4caf50"
                    />
                    <path
                      d="M452.95,234.965a40.791,40.791,0,0,1-21.333,36.267L352.9,314.325l-82.56-79.36L352.9,155.6l78.72,43.093A40.791,40.791,0,0,1,452.95,234.965Z"
                      fill="#ffc107"
                    />
                    <path
                      d="M352.9,314.325,77.91,464.725a40.9,40.9,0,0,1-40.747-.64,31.44,31.44,0,0,1-2.773-1.92l235.947-227.2Z"
                      fill="#f44336"
                    />
                  </g>
                </svg>

                <div className="text">
                  <p className="text-xs text-gray-600">Descargar desde</p>
                  <p className="text-xs font-semibold text-gray-900">
                    Google play
                  </p>
                </div>
              </a>
            </div>
          </section>

          <section id="about" className="py-3">
            <div className="w-full">
              <h2 className="text-red-500">Acerca de</h2>
              <h1 className="text-3xl font-semibold mb-4">¿Cómo funciona?</h1>
            </div>
            <div className="w-full flex flex-wrap items-start justify-center">
              <section className="lg:w-2/5 md:w-2/4 w-4/4 sm:w-2/4 text-left mt-3 items-start justify-start my-6 px-3 pl-0">
                <div className="rounded">
                  <div className="items-center justify-start flex">
                    <div className="bg-primary p-2 rounded-2xl">
                      <CursorClickIcon className="w-7 text-secondary" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h1 className="font-semibold text-xl my-1">
                    Seleccionar maestros
                  </h1>
                  <p className="text-xl">
                    Busca tus maestros y excluye los que te desagraden para cursar
                    una materia, no se considerarán para la generación del
                    horario.
                  </p>
                </div>
              </section>

              <section className="lg:w-2/5 md:w-2/4 w-4/4 sm:w-2/4 text-left mt-3 items-start justify-start my-6 px-3 pl-0">
                <div className="rounded">
                  <div className="items-center justify-start flex">
                    <div className="bg-primary p-2 rounded-2xl">
                      <CogIcon className="w-7 text-secondary" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h1 className="font-semibold text-xl my-1">Ajustar horarios</h1>
                  <p className="text-xl">
                    Configura de qué a qué hora deseas tener tus clases,
                    considerando un rango de horas posible de acuerdo al número de
                    materias que cursaras.
                  </p>
                </div>
              </section>

              <section className="lg:w-2/5 md:w-2/4 w-4/4 sm:w-2/4 text-left mt-3 items-start justify-start my-6 px-3 pl-0">
                <div className="rounded">
                  <div className="items-center justify-start flex">
                    <div className="bg-primary p-2 rounded-2xl">
                      <SaveIcon className="w-7 text-secondary" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h1 className="font-semibold text-xl my-1">Guardar horarios</h1>
                  <p className="text-xl">
                    Almacena todos los horarios que te gusten y consideres posible
                    para la próxima agenda.
                  </p>
                </div>
              </section>

              <section className="lg:w-2/5 md:w-2/4 w-4/4 sm:w-2/4 text-left mt-3 items-start justify-start my-6 px-3 pl-0">
                <div className="rounded">
                  <div className="items-center justify-start flex">
                    <div className="bg-primary p-2 rounded-2xl">
                      <DesktopComputerIcon className="w-7 text-secondary" />
                    </div>
                  </div>
                </div>
                <div className="text-left">
                  <h1 className="font-semibold text-xl my-1">
                    Agendar un horario
                  </h1>
                  <p className="text-xl">
                    Es día de agenda, ve tus horarios guardados y análiza el cupo
                    de cada uno, agenda!{" "}
                  </p>
                </div>
              </section>
            </div>
          </section>

          <section id="support" className="py-4 pb-12 ">
            <h2 className="text-green-500 ">Contribuciones</h2>
            <h1 className="text-3xl font-semibold">
              Por estudiantes, para estudiantes
            </h1>
            <p className="text-xl my-2">
              Este proyecto está creado y soportado por la comunidad estudiantil
              de la Universidad de Guadalajara.
            </p>
            <button
              className="bg-primary text-secondary font-medium py-2 px-4 rounded hover:opacity-80 w-40"
              onClick={handleContribute}
            >
              Contribuir
            </button>
          </section>

          <section id="faq" className="py-4 pb-12 ">
            <h2 className="text-pink-500 ">Preguntas</h2>
            <h1 className="text-3xl font-semibold">Preguntas frecuentes</h1>
            <p className="text-xl my-2">
              A continuación puedes ver las preguntas o dudas más repetitivas que
              existen acerca del servicio
            </p>
            <div className="flex flex-wrap">
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>
                        ¿El servicio funciona para toda la red universitaria?
                      </span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Agendador UDG hace uso directo de la plataforma de SIIAU
                      para extraer los datos de los horarios. Mientras este sea
                      público y funcione para tu centro, puedes confiar en su
                      funcionamiento.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>¿Cómo se sostiene económicamente el proyecto?</span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      El proyecto actulamente sólo genera dinero de los anuncios
                      mostrados en la aplicación, sin embargo, estos aún no
                      alcanzan para sostener el proyecto. Se siguen evaluando
                      opciones para lograr este fin.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>¿Agendador UDG es gratis?</span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Sí. El servicio de creador de horarios y las evaluaciones de
                      maestros hechas por estudiantes son y serán gratuitos.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>
                        ¿Para qué una página si ya está funcionando la app?
                      </span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      Algunos servicios como el buscador de maestros, no deberían
                      de ser condicionados por tener descargada la app. Entre
                      mayor accesible sea su uso, mejor.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>¿Hay alguna manera de apoyar al proyecto?</span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      El recomendar la aplicación con amigos y contribuir con tus
                      evaluaciones son las principales fuentes de apoyo y ayuda,
                      se agradecen mucho ♥️
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>
                        {" "}
                        ¿Agendador UDG es propiedad de la Universidad de
                        Guadalajara?
                      </span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      No. Es un proyecto estudiantil sin fines de lucro.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure as="div" className=" sm:w-1/2 w-full p-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-secondary bg-yellow-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>
                        {" "}
                        ¿Recibes algún apoyo de cualquier tipo por parte de UdeG?
                      </span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      No 👍
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
