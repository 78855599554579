/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const deleteForm = 'https://docs.google.com/forms/d/e/1FAIpQLSdjHfDiBOP_TyXzDuxaWnlBLfyKPeYO-uUHnzonCOlhq2PWxw/viewform?embedded=true';

export default class DeleteAccountPage extends React.Component {

  componentDidMount() {
    document.title = 'Eliminar cuenta';
  }

  render() {
    return (
      <div>
        <iframe src={deleteForm} className="h-screen w-screen m-0 p-0 border-0">Cargando…</iframe>
      </div>
    );
  }
}