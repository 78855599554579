import { Helmet } from "react-helmet";

interface SeoPageProps {
  title: string;
  description: string;
}

export const SEOPage = ({ title, description }: SeoPageProps) => {
  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: "es" }}
      meta={[
        {
          property: "twitter:card",
          content: "summary",
        },
        {
          property: "twitter:creator",
          content: "@diezep",
        },
        {
          property: "twitter:title",
          content: title,
        },
        {
          property: "twitter:description",
          content: description,
        },
        {
          property: "twitter:image",
          content: `${window.location.host}/tw.png`,
        },
        {
          property: "og:url",
          content: window.location.href,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:image",
          content: `${window.location.host}/tw.png`,
        },
        {
          property: "fb:app_id",
          content: "323159565778095",
        },
        {
          property: "og:type",
          content: "website",
        },
      ]}
    />
  );
};
