import { Campus } from "@agendadorudg/models";
import { useEffect, useState } from "react";

export default function SearcherInput() {
  const [campus, setCampus] = useState<Campus[]>([]);
  const [activeCampus] = useState<Campus>();
  const [defaultValue ] = useState<string>(localStorage.getItem("campus") || "");
  
  
  // Function getActiveCampus that returns list of active campus
  // const firestore = getFirestore();
  // const docRef = doc(firestore, FIRESTORE_CAMPUS);
  
  // const {  data, isComplete, error} = useFirestoreDocOnce(docRef);
  
  // useEffect(() => {
  //   if(!error && isComplete) {
  //     const _campus: Array<Campus> = data.data()!["campus"];
  //     const active = _campus.filter((campus) => campus.searcher ?? false);
  //     setCampus(active);
  //   }
    
  // }, [isComplete]);
  
  useEffect(()=>{
    setCampus([
      {
        id: 'CUCEI',
        shortName:"CUCEI",
        name:'CUCEI'
      },
    ])
  },[])
  return <form action="/buscador" method="get" >
    <div className="flex w-full max-w-xl border-2 rounded-md outline mb-4 text-xl">
      <select required className="h-full inline-flex items-center py-3 px-4 text-center text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200 border-r" value={activeCampus?.shortName} defaultValue={defaultValue} placeholder="Campus" name="campus" onChange={(e)=>localStorage.setItem("campus", e.target.value)}>
      {!defaultValue && <option value={defaultValue} disabled>{defaultValue?defaultValue:'Campus'}</option>}
        {campus.map((_campus: Campus) => (
          <option key={_campus.id} className="text-secondary flex items-center w-full px-2 py-2" value={_campus.shortName}>
            {_campus.shortName}
          </option>
        ))}
      </select>

      <div className="flex w-full">
        <input type="search" name="q" id="search-dropdown" className="w-full inline-flex py-2.5 px-4" placeholder="Buscar maestros y materias por clave o nombre" required />
        <button type="submit" className="flex justify-center items-center p-2.5 font-medium text-secondary bg-primary">
          <span className="ml-1">Buscar</span>
        </button>
      </div>

    </div>
  </form>;

}