import { Evaluation, EvaluationModality, Teacher } from "@agendadorudg/models";
import { Transition } from "@headlessui/react";
import { ChatAlt2Icon, XIcon } from "@heroicons/react/solid";
import { useState } from "react";
import Rating from "react-rating";
import { LIMIT_COMMENT_EVALUATION_LENGTH } from "../constants";
import TeacherPills from "./TeacherPills";

const hasEmptyAttrs = (ev: Evaluation): boolean =>
  ev?.comment &&
  ev?.knowledge &&
  ev?.difficulty &&
  ev?.modality &&
  ev?.punctuality
    ? false
    : true;

export default function EvaluateTeacherCard(props: {
  teacher: Teacher;
  evaluation?: Evaluation;
  onRemoveTeacher: Function;
  onEvaluatedTeacher: Function;
  headless?: boolean;
}) {
  const { teacher, onRemoveTeacher, headless = false } = props;
  const [evaluation, setEvaluation] = useState<Evaluation>(
    props.evaluation ??
      ({
        teacher: {
          id: teacher.id,
          name: teacher.name,
        },
      } as Evaluation)
  );
  const [evaluated, setEvaluated] = useState<boolean>(
    props.evaluation?.id !== undefined
  );
  const [loadingEvaluation, setLoadingEvaluation] = useState<boolean>(false);
  const [showed, setShowed] = useState<boolean>(true);

  async function handleEvaluationSubmit() {
    setLoadingEvaluation(true);
    await props.onEvaluatedTeacher(evaluation);
    await new Promise((r) => setTimeout(() => r(""), 100));
    setEvaluated(true);
    await new Promise((r) => setTimeout(() => r(""), 3000));
    setShowed(false);
  }

  const radioOptions = [
    {
      text: "Presencial",
      value: EvaluationModality.CLASSROOM_BASED as EvaluationModality,
    },
    {
      text: "Virtual",
      value: EvaluationModality.VIRTUAL as EvaluationModality,
    },
    {
      text: "Híbrida",
      value: EvaluationModality.HYBRID as EvaluationModality,
    }
  ];

  return (
    <>
      <Transition
        static
        show={showed}
        unmount={!showed}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-250"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="m-1"
      >
        <div className="w-full p-3 pt-4 relative duration-150 rounded-md hover:bg-gray-50">
          <div>
            <div className="flex justify-between">
              <p className="font-semibold text-secondary">
                {headless ? "Tarjeta de Evaluación" : teacher.name}
              </p>
              <XIcon
                onClick={() => onRemoveTeacher(teacher)}
                className="right-0 top-1 text-gray-400 h-5 w-5 transform hover:rotate-90 hover:text-gray-800 transition duration-150"
              />
            </div>
            {!headless && (
              <TeacherPills
                campus={teacher.campus ?? []}
                subjects={teacher.subjects ?? []}
              />
            )}
          </div>

          <div className="flex flex-wrap pt-2">
            <div id="modalidad" className="my-2">
              <div>
                <p className="mb-1">Modalidad*</p>
                <div className="mt-2">
                  {radioOptions.map((b) => (
                    <label
                      aria-required
                      className="inline-flex items-center mr-8"
                      key={b.value}
                    >
                      <input
                        type="radio"
                        className="form-radio text-secondary"
                        name={`modality-${teacher.id}`}
                        onChange={(v) =>
                          setEvaluation({
                            ...evaluation,
                            modality: v.currentTarget.value,
                          })
                        }
                        value={b.value}
                      />
                      <span className="ml-2">{b.text}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div id="difficulty" className="my-2">
              <p className="mb-1">Dificultad*</p>
              <Rating
                initialRating={evaluation.difficulty}
                onChange={(v) =>
                  setEvaluation({ ...evaluation, difficulty: v })
                }
                className="relative border"
                fullSymbol={Array.from({ length: 10 }, (_, i) => i + 1).map(
                  (n) => (
                    <span className="text-white bg-secondary px-2 py-1">
                      {n}
                    </span>
                  )
                )}
                emptySymbol={Array.from({ length: 10 }, (_, i) => i + 1).map(
                  (n) => (
                    <span className="px-2 py-1">{n}</span>
                  )
                )}
                placeholderSymbol={Array.from(
                  { length: 10 },
                  (_, i) => i + 1
                ).map((n) => (
                  <span className="px-2 py-1">{n}</span>
                ))}
                step={1}
                stop={10}
              />
            </div>
            <div id="knowledge" className="my-2 sm:mr-4 md:mr-0">
              <p className="mb-1">Conocimiento*</p>
              <Rating
                initialRating={evaluation.knowledge}
                onChange={(v) => setEvaluation({ ...evaluation, knowledge: v })}
                className="relative border"
                fullSymbol={Array.from({ length: 10 }, (_, i) => i + 1).map(
                  (n) => (
                    <span className="text-white bg-secondary px-2 py-1">
                      {n}
                    </span>
                  )
                )}
                emptySymbol={Array.from({ length: 10 }, (_, i) => i + 1).map(
                  (n) => (
                    <span className="px-2 py-1">{n}</span>
                  )
                )}
                step={1}
                stop={10}
              />
            </div>
            <div id="puntuaclity" className="my-2">
              <p className="mb-1">Puntualidad*</p>
              <Rating
                initialRating={evaluation.punctuality}
                onChange={(v) =>
                  setEvaluation({ ...evaluation, punctuality: v })
                }
                className="relative border"
                fullSymbol={Array.from({ length: 10 }, (_, i) => i + 1).map(
                  (n) => (
                    <span className="text-white bg-secondary px-2 py-1">
                      {n}
                    </span>
                  )
                )}
                emptySymbol={Array.from({ length: 10 }, (_, i) => i + 1).map(
                  (n) => (
                    <span className=" px-2 py-1">{n}</span>
                  )
                )}
                step={1}
                stop={10}
              />
            </div>
            <div className="w-full">
              <p className="mb-1">Comentario*</p>
              <label
                htmlFor="search-teacher"
                className="relative text-gray-400 focus-within:text-gray-600 block"
              >
                <ChatAlt2Icon className="pointer-events-none w-6 h-6 absolute top-5 transform -translate-y-1/2 left-3" />
                <textarea
                  required
                  disabled={evaluated || loadingEvaluation}
                  form={"evaluacion"}
                  maxLength={LIMIT_COMMENT_EVALUATION_LENGTH}
                  name="search-teacher"
                  className="pl-12 pb-12 pt-2 text-sm md:text-base w-full border rounded-md outline-none focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="¿Qué le dirias a un amigo de este maestro?"
                  value={evaluation.comment}
                  onChange={(v) =>
                    setEvaluation({
                      ...evaluation,
                      comment: v.currentTarget.value,
                    })
                  }
                />
              </label>
            </div>
            <div className="w-full flex flex-wrap justify-center mt-3">
              {loadingEvaluation ? (
                <>
                  <div className="flex items-center justify-center">
                    {evaluated ? (
                      <p className="text-sm sm:text-base">
                        Evaluado. Gracias por aportar 🥳
                      </p>
                    ) : (
                      <>
                        <svg
                          className="animate-spin h-5 w-2 mr-3 bg-primary"
                          viewBox="0 0 24 24"
                        ></svg>

                        <p className="">Evaluando maestro</p>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label
                    htmlFor="politicas"
                    className="flex flex-wrap text-left w-full mb-2 text-xs text-gray-500"
                  >
                    <span className="ml-2 w-full">
                      Al enviar la evaluación, confirmas que cumple con las{" "}
                      <a
                        href="/politicas#evaluaciones"
                        className="text-blue-500 underline"
                      >
                        Reglas para evaluaciones
                      </a>
                    </span>
                    <span className="ml-2 mt-2">Tu evaluación es anónima</span>
                  </label>
                  <button
                    type="button"
                    disabled={hasEmptyAttrs(evaluation) || loadingEvaluation}
                    form={"evaluacion"}
                    onClick={handleEvaluationSubmit}
                    className={
                      "text-secondary py-1 px-2 rounded w-full md:w-32 text-lg " +
                      (hasEmptyAttrs(evaluation) || loadingEvaluation
                        ? "bg-primary opacity-50 cursor-not-allowed"
                        : "bg-primary hover:opacity-80")
                    }
                  >
                    Evaluar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
