import { Transition, Menu } from "@headlessui/react";
import { Fragment } from "react";
// import ReactTooltip from "react-tooltip";
import { Teacher } from "@agendadorudg/models";

import TeacherPills from "./TeacherPills";

export default function TeachersDropdown(props: {
  teachers: Teacher[];
  onSelectTeacher: Function;
}) {
  return (
    <Menu
      as="div"
      className="absolute z-50 left-0 w-full md:w-10/12 lg:w-9/12 md:pr-0 px-8 xl:px-72 lg:px-44 md:px-24"
    >
      <Transition
        as={Fragment}
        show={props.teachers.length > 0}
        enter="transition duration-300 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-300 opacity-300"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-300 opacity-300"
        leaveTo="transform scale-95 opacity-0"
      >
        {/* Mark this component as `static` */}
        <Menu.Items
          static
          as="ul"
          className="max-h-96 overflow-y-scroll rounded-md border shadow p-2 bg-white"
        >
          {props.teachers.map((t: Teacher) => {
            return (
              <Menu.Item
                as="li"
                key={t.id}
                className="p-2 bg-white hover:bg-gray-200 cursor-pointer rounded-md"
                onClick={() => props.onSelectTeacher(t)}
              >
                <div className="font-medium">{t.name}</div>
                <TeacherPills campus={t.campus} subjects={t.subjects} />
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
